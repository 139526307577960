import React from "react";
import queryString from "query-string";

// seo
import Seo from "../components/Seo";

// App Header
// import AppHeaderTwinApp from "../components/AppHeader/AppHeaderTwinApp";
import AppHeader from "../components/AppHeader/AppHeader";

// Components
import ReviewJournal from "../components/ReviewJournal/ReviewJournalV1";
import GetInTouch from "../components/Home/GetInTouch/GetInTouch";
import FooterV1 from "../components/Footer/FooterV1";

const Journals = props => {
  const { twinApp, appHeader } = queryString.parse(props.location.search);

  return (
    <>
      <Seo
        title="Diabetes Quiz | Diabetes Reversal Program"
        description="Diabetes Quiz. 1000's have reversed their diabetes using Twin's proven AI based precision treatment."
      />

      {/* Start ---> Header for Twin App */}
      {/* {appHeader === "no" && twinApp === "yes" && (
        <AppHeaderTwinApp
          text=""
          isBack={true}
          backUrl="mobile_back"
          bgColor="bgWhite"
        />
      )} */}

      {appHeader === "mobile" && twinApp === "yes" && (
        <AppHeader text="" isBack={true} backUrl="mobile_back" />
      )}
      {/* End ---> Header for Twin App */}

      <ReviewJournal appHeader={appHeader} twinApp={twinApp} />
      <GetInTouch />
      <FooterV1 isHome={false} />
    </>
  );
};

export default Journals;
