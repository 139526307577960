import React from "react";
import Modal from "react-bootstrap/Modal";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";

// styles
import * as styles from "./GetInTouch.module.css";

const ThankYouModal = props => {
  return (
    <>
      <Modal
        show={props.show}
        onHide={props.onHide}
        backdrop="static"
        keyboard={false}
        centered={true}
        size="lg"
        // bsPrefix="modal"
      >
        <Modal.Header closeButton className={styles.closeBtn}>
          <div className="d-block d-lg-none d-xl-none d-xxl-none">&nbsp;</div>
        </Modal.Header>
        <Modal.Body className="ps-4 pe-4">
          <div className={`mt-3 text-center ${styles.thankYouMsg}`}>
            <StaticImage
              src="../../../images/home/thank-you.png"
              alt="ehr"
              className={`img-fluid ${styles.thankYouMsg_image}`}
              placeholder="blurred"
              quality={100}
            />
            <h2>Thank you</h2>
            <p>We have received your request and will get back to you soon.</p>
            <Link to="/" className="navLink" onClick={() => props.onHide()}>
              Click here to know more about{" "}
              <strong>
                Whole Body Digital Twin Technology <sup>TM</sup>
              </strong>
            </Link>
            <div className={` ${styles.thankYouMsg_line}`}></div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ThankYouModal;
