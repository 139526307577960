import React, { useState } from "react";
import Loader from "react-loader-spinner";
import { useForm } from "react-hook-form";
// import { StaticImage } from "gatsby-plugin-image";
// import { debounce } from "lodash";

// styles
import * as styles from "./GetInTouch.module.css";

// constants
// import constants from "../../../constants";

// components
// import DropDown from "../../DropDown/DropDown";
import ThankYouModal from "./ThankYouModal";

// services
import { sendToLSQ } from "../../../services/LeadSquared";

// utils
import { splitName } from "../../../utils";

const GetInTouchForm = props => {
  // const [selectedLanguage, setSelectedLanguage] = useState([]); // selected language values
  // const [showLanguageError, setShowLanguageError] = useState("none"); // show error for language selection if left empty

  // const [selectedTime, setSelectedTime] = useState([]);
  // const [showTimeError, setShowTimeError] = useState("none");

  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  // const languageRef = useRef();
  // const timeRef = useRef();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({ mode: "onBlur" });

  const onSubmit = async data => {
    // const languageArr = selectedLanguage.map(item => item.name);
    // const timeArr = selectedTime.map(item => item.name);

    // data.language = languageArr.join();
    // data.time = timeArr.join();

    // console.log("form:", data);

    // util function -> to split the name
    const { firstName, lastName } = splitName(data.name);

    // Saving User Data To LSQ
    const leadSquaredParams = {
      FirstName: firstName,
      LastName: lastName,
      Phone: data.phone,
      // EmailAddress: data.email,
      // mx_City: data.city,
      // mx_Language: data.language,
      // mx_Preferred_time_of_call: data.time,
      mx_Website_CTA: "India_home_page",
      mx_Latest_Source: "New Website",
    };

    try {
      setLoading(true);
      // LSQ Call
      await sendToLSQ(leadSquaredParams);
      setLoading(false);
      handleShow();
      reset();
      // languageRef.current.resetSelectedValues();
      // setSelectedLanguage([]);
      // setShowLanguageError("none");
      // timeRef.current.resetSelectedValues();
      // setSelectedTime([]);
      // setShowTimeError("none");
    } catch (error) {
      console.error("lsq error", error);
      setLoading(false);
    }
  };

  // Handle language selection
  // const handleLanguageSelection = value => {
  //   if (value.length > 0) {
  //     setShowLanguageError("none");
  //     setSelectedLanguage(value);
  //   } else {
  //     setShowLanguageError("block");
  //   }

  //   // auto closing the popup
  //   languageRef.current &&
  //     value.length > 1 &&
  //     debounce(() => {
  //       languageRef.current.searchBox.current.blur();
  //     }, 1000)();
  // };

  // const handleTimeSelection = value => {
  //   if (value.length > 0) {
  //     setShowTimeError("none");
  //     setSelectedTime(value);
  //   } else {
  //     setShowTimeError("block");
  //   }
  // };

  return (
    <>
      <form
        name="contactUs"
        onSubmit={handleSubmit(onSubmit)}
        className={`mt-3`}
      >
        <div className="row">
          <div className="col-12 col-md-6">
            <div className={`form-group mb-4 mb-md-5 ${styles.formInput}`}>
              <input
                className={
                  errors.name ? "form-control is-invalid" : "form-control"
                }
                placeholder="Full Name*"
                id="name"
                type="text"
                {...register("name", {
                  required: "Please enter your name",
                  pattern: {
                    value: /[a-zA-Z][a-zA-Z ]*/, // eslint-disable-line no-useless-escape
                    message: "Please enter your valid name",
                  },
                  maxLength: { value: 50, message: "Name is too long" },
                })}
              />
              {errors.name && (
                <span className="invalid-feedback">{errors.name.message}</span>
              )}
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className={`form-group mb-4 mb-md-5 ${styles.formInput}`}>
              <input
                className={`form-control letterSp1 ${
                  errors.phone ? "is-invalid" : ""
                }`}
                placeholder="Mobile Number*"
                id="phone"
                type="text"
                inputMode="numeric"
                maxLength="10"
                {...register("phone", {
                  required: "Please enter your mobile number",
                  pattern: {
                    value: /^\+?([1-9]{1})\)?[-. ]?([0-9]{5})[-. ]?([0-9]{4})$/, // eslint-disable-line no-useless-escape
                    message: "Please enter your valid phone number",
                  },
                  maxLength: {
                    value: 10,
                    message: "Invalid phone number",
                  },
                })}
              />
              {errors.phone && (
                <span className="invalid-feedback">{errors.phone.message}</span>
              )}
            </div>
          </div>

          <div className="col-12 col-md-6 d-none">
            <div className={`form-group mb-4 mb-md-5 ${styles.formInput}`}>
              <input
                className="form-control"
                // className={
                //   errors.email ? "form-control is-invalid" : "form-control"
                // }
                placeholder="Email*"
                id="email"
                type="email"
                // {...register("email", {
                //   required: "Please enter your email",
                //   pattern: {
                //     value:
                //       /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i, // eslint-disable-line no-useless-escape
                //     message: "Please enter your valid email",
                //   },
                // })}
              />
              {/* {errors.email && (
                <span className="invalid-feedback">{errors.email.message}</span>
              )} */}
            </div>
          </div>

          <div className="col-12 col-md-6 d-none">
            <div className={`form-group mb-4 mb-md-5 ${styles.formInput}`}>
              <input
                // className={
                //   errors.city ? "form-control is-invalid" : "form-control"
                // }
                className="form-control"
                placeholder="City*"
                id="city"
                type="text"
                // {...register("city", {
                //   required: "Please enter your city",
                //   pattern: {
                //     value: /[a-zA-Z][a-zA-Z ]*/, // eslint-disable-line no-useless-escape
                //     message: "Please enter your valid city",
                //   },
                //   maxLength: { value: 50, message: "City is too long" },
                // })}
              />
              {/* {errors.city && (
                <span className="invalid-feedback">{errors.city.message}</span>
              )} */}
            </div>
          </div>

          {/* <div className={`col-12 col-md-6 d-none`}>
            <div
              className={`form-group mb-4 mb-md-5 ${styles.bookpackForm__lngFormInput}`}
            > */}
              {/* <DropDown
                singleSelect={false}
                displayValue="display"
                onSelect={handleLanguageSelection}
                onRemove={handleLanguageSelection}
                options={constants.GET_IN_TOUCH_LANGUAGES}
                showCheckbox={true}
                selectionLimit={2}
                value={selectedLanguage}
                placeholder="Language preference (any two languages)*"
                hidePlaceholder={true}
                showArrow={true}
                ref={languageRef}
                customCloseIcon={
                  <StaticImage
                    src="../../../images/bookPack/cross_2.png"
                    alt="cross_icon"
                    className="img-fluid"
                    placeholder="blurred"
                    quality={90}
                    style={{
                      width: "14px",
                      height: "14px",
                      marginTop: "2px",
                      marginLeft: "2px",
                    }}
                  />
                }
                customArrow={
                  <StaticImage
                    src="../../../images/bookPack/arrow_dropdown.png"
                    alt="cross_icon"
                    className="img-fluid"
                    placeholder="blurred"
                    quality={90}
                    style={{
                      marginTop: "9px",
                    }}
                  />
                }
              /> */}
              {/* To show language error */}
              {/* {selectedLanguage.length === 0 && (
                <span
                  className="text-danger"
                  style={{
                    fontSize: "0.875em",
                    display: showLanguageError,
                  }}
                >
                  <p style={{ marginTop: "4px" }}>Please select a language</p>
                </span>
              )} */}
            {/* </div>
          </div> */}

          {/* <div className={`col-12 col-md-6 d-none`}>
            <div
              className={`form-group mb-4 mb-md-5 ${styles.bookpackForm__lngFormInput}`}
            > */}
              {/* <DropDown
                singleSelect={true}
                displayValue="display"
                onSelect={handleTimeSelection}
                onRemove={handleTimeSelection}
                options={constants.GET_IN_TOUCH_TIMES}
                showCheckbox={false}
                value={selectedTime}
                placeholder="Preferred time to call*"
                hidePlaceholder={true}
                showArrow={true}
                ref={timeRef}
                customCloseIcon={
                  <StaticImage
                    src="../../../images/bookPack/cross_2.png"
                    alt="cross_icon"
                    className="img-fluid"
                    placeholder="blurred"
                    quality={90}
                    style={{
                      width: "14px",
                      height: "14px",
                      marginTop: "2px",
                      marginLeft: "2px",
                    }}
                  />
                }
                customArrow={
                  <StaticImage
                    src="../../../images/bookPack/arrow_dropdown.png"
                    alt="cross_icon"
                    className="img-fluid"
                    placeholder="blurred"
                    quality={90}
                    style={{
                      marginTop: "9px",
                    }}
                  />
                }
              /> */}
              {/* To show language error */}
              {/* {selectedTime.length === 0 && (
                <span
                  className="text-danger"
                  style={{
                    fontSize: "0.875em",
                    display: showTimeError,
                  }}
                >
                  <p style={{ marginTop: "4px" }}>
                    Please select a preferred time
                  </p>
                </span>
              )} */}
            {/* </div>
          </div> */}

          <div className="col-12">
            <div className={`form-group mb-3`}>
              <div class="custom-control">
                <div className="d-flex align-items-center">
                  <input
                    className={`custom-control-input ${styles.checkbox}`}
                    type="checkbox"
                    checked="checked"
                    id="flexCheckChecked"
                    {...register("terms", {
                      required: "Please check contacted by Twin Health via Call, SMS, WhatsApp & Email box",
                    })}
                  />
                  <label
                    className={`custom-control-label ${styles.checkLabel}`}
                    for="flexCheckChecked"
                    htmlFor="terms-checkbox"
                  >
                    I agree to be contacted by Twin Health via Call, SMS,
                    WhatsApp & Email
                  </label>
                </div>
                {errors.terms && (
                  <span className={styles.invalid}>{errors.terms.message}</span>
                )}
              </div>
            </div>
          </div>

          <div className="col-12 mt-4 mt-md-5 text-center">
            <button
              type="submit"
              // onClick={() => {
              //   setShowLanguageError("block");
              //   setShowTimeError("block");
              // }}
              className={`btn ${styles.submitBtn} ${loading && "btnEvent"}`}
            >
              {!loading ? (
                <>
                  <div>Submit </div>
                </>
              ) : (
                <span className={"pt-1 pb-1"}>
                  <Loader
                    type="TailSpin"
                    color="#FFF"
                    height={30}
                    width={30}
                    radius={1}
                    visible={loading}
                  />
                </span>
              )}
            </button>
          </div>
        </div>
      </form>

      <ThankYouModal show={show} onHide={() => handleClose()} />
    </>
  );
};

export default GetInTouchForm;
