export const payload = [
  {
    id: 101,
    title: "Journal of Hypertension",
    desc: "Remission of Hypertension at Six months of Whole Body Digital Twin Technology.",
    url: "https://journals.lww.com/jhypertension/Abstract/2023/06003/REMISSION_OF_HYPERTENSION_AT_SIX_MONTHS_OF_WHOLE.279.aspx",
  },
  {
    id: 102,
    title: "Endocrine Practice - the Journal for Clinical Endocrinology",
    desc: "Whole Body Digital Twin Technology for Remission of T2DM and Improvement in Fatty Liver",
    url: "https://www.endocrinepractice.org/article/S1530-891X(23)00129-5/fulltext",
  },
  {
    id: 103,
    title: "Endocrine Practice - the Journal for Clinical Endocrinology",
    desc: "Whole Body Digital Twin Technology for Remission of T2DM and Normalization of Glycemic and Fatty Liver Parameters",
    url: "https://www.endocrinepractice.org/article/S1530-891X(23)00130-1/fulltext",
  },
  {
    id: 104,
    title: "Diabetes Technology & Therapeutics",
    desc: "Remission of Type 2 Diabetes And Regression Of Microalbuminuria With The Whole-Body Digital Twin Technology: A Multicentric, Randomized, Controlled Trial.",
    url: "https://www.liebertpub.com/doi/10.1089/dia.2023.2525.abstracts",
  },
  {
    id: 105,
    title: "Diabetes Technology & Therapeutics",
    desc: "Correlation of Continuous Glucose Monitoring Metrics with HbA1c reduction And Beta Cell Function at one year of Whole-Body Digital Twin Technology for Remission of Diabetes.",
    url: "https://www.liebertpub.com/doi/10.1089/dia.2023.2525.abstracts",
  },

  {
    id: 106,
    title: "Diabetes Research and Clinical Practice",
    desc: "Improvement in the Components of Metabolic Syndrome with Remission of T2DM through Whole-body Digital Twin Technology (Abstract)",
    url: "",
  },
  {
    id: 107,
    title: "Circulation - American Heart Association Journal",
    desc: "Concordance between the Various Cardiovascular Risk Scores: Six Months Results of the Randomised Clinical Trial Evaluating Whole-Body Digital Twin Technology Enabled Precision Nutrition for Remission of Diabetes",
    url: "https://www.ahajournals.org/doi/abs/10.1161/circ.146.suppl_1.14842",
  },
  {
    id: 108,
    title:
      "Proceedings for the 20th Annual Conference of Diabetic Foot Society India",
    desc: "Change in the sensitometer findings in T2DM undergoing remission: Insights from the one year of Digital Twin Intervention Trial (Abstract)",
    url: "",
  },
  {
    id: 109,
    title: "Endocrine Practice - the Journal for Clinical Endocrinology",
    desc: "Quantification of Adiposity, Liver and Pancreas Fat with Magnetic Resonance Imaging in T2DM: Insights From the One Year Of Digital Twin Intervention Trial",
    url: "https://www.endocrinepractice.org/article/S1530-891X(22)00808-4/fulltext",
  },
  {
    id: 110,
    title: "Diabetologia",
    desc: "Correlation between the regression of microalbuminuria, Blood pressure reduction and remission of T2DM: Results of Six Month RCCT with Whole-Body Digital Twin Technology",
    url: "https://link.springer.com/article/10.1007/s00125-022-05755-w",
  },
  {
    id: 111,
    title: "Journal of Hypertension",
    desc: "Correlation Between Remission Of Diabetes And Reductions In Insulin Resistance, Blood Pressure And Cardiovascular Risk Scores: Results Of Six Months Of Randomised Trial With Digital Twin Technology",
    url: "https://journals.lww.com/jhypertension/Abstract/2022/06001/CORRELATION_OF_DIABETES_REMISSION_WITH_REDUCTIONS.160.aspx",
  },
  {
    id: 112,
    title: "Journal of the Endocrine Society",
    desc: "Effectiveness Of Digital Twin Technology For The Improvement In Depression Among Patients Undergoing Remission Of Diabetes",
    url: "https://academic.oup.com/jes/article/6/Supplement_1/A279/6787442",
  },
  {
    id: 113,
    title: "Journal of the Endocrine Society",
    desc: "Concordance between HbA1c, eA1C and fasting plasma glucose criteria to define diabetes remission: Insights from the Initial Six months of Digital Twin Intervention Trial",
    url: "https://academic.oup.com/jes/article/6/Supplement_1/A385/6788404",
  },
  {
    id: 114,
    title: "Diabetes - American Diabetes Association Journal",
    desc: "Normalisation of Glycemic and Extra-Glycemic Parameters with T2DM Remission: Insights from RCT enabled by Whole-Body Digital Twin Technology.",
    url: "https://diabetesjournals.org/diabetes/article/71/Supplement_1/221-LB/146396/221-LB-Normalisation-of-Glycemic-and-Extraglycemic",
  },
  {
    id: 115,
    title: "Diabetes - American Diabetes Association Journal",
    desc: "Remission of Type 2 Diabetes and improvement in Metabolic Markers with the Twin Precision Technology (TPT) - A Multicenter, Randomized, Controlled Trial",
    url: "https://diabetesjournals.org/diabetes/article/71/Supplement_1/4-LB/145894",
  },
  {
    id: 116,
    title: "Diabetes - American Diabetes Association Journal",
    desc: "Metabolic Benefits Beyond Glycemic Control with Artificial Intelligence, Internet of Things and Whole-Body Digital Twin - Initial six months Treatment.",
    url: "https://diabetesjournals.org/diabetes/article/71/Supplement_1/26-OR/146723",
  },
  {
    id: 117,
    title: "Endocrine Practice - the Journal for Clinical Endocrinology",
    desc: "Predictors Of Type 2 Diabetes (T2D) Remission Using Digital Twin Technology Based On Artificial Intelligence (AI) And Internet Of Things (IoT) Technologies.",
    url: "https://www.endocrinepractice.org/article/S1530-891X(22)00201-4/fulltext",
  },
  {
    id: 118,
    title: "Proceedings of the European Association for the Study of Obesity",
    desc: "Impact of Digital Twin technology based on Artificial Intelligence (AI) and Internet of Things (IoT) technologies on diabetes remission and correlation between progressive weight loss with Insulin resistance, waist circumference",
    url: "",
  },
  {
    id: 119,
    title:
      "Proceedings of the 3rd International Conference on Fatty Liver (ICFL)",
    desc: "Digital Twin Technology For Remission Of Diabetes And Fatty Liver: Initial Results At 6 Months Of Follow up of The Prospective Randomised Controlled Clinical Trial (Abstract).",
    url: "",
  },
  {
    id: 120,
    title: "Diabetes Technology & Therapeutics",
    desc: "Association of The Remission of T2DM by Digital Twin Technology with The Improvement In The Glycemic Metrics (Abstract)",
    url: "https://www.liebertpub.com/doi/10.1089/dia.2022.2525.abstracts",
  },
  {
    id: 121,
    title: "Diabetes Research and Clinical Practice",
    desc: "Remission of T2DM by Digital Twin Technology Across Indian and American Cohorts- Initial Insights from Real-World Study (Abstract).",
    url: "https://www.diabetesresearchclinicalpractice.com/article/S0168-8227(22)00391-6/fulltext",
  },
  {
    id: 122,
    title: "Metabolism - Clinical and Experimental",
    desc: "Glycemic Control with Improvement in HOMA2 IR and HOMA2 Beta: Impact of Digital Twin Enabled Precision Nutrition for Remission of Diabetes(Abstract)",
    url: "https://www.metabolismjournal.com/article/S0026-0495(21)00407-8/fulltext",
  },
  {
    id: 123,
    title: "Proceedings of the My ESC - Your Technology - ESC Digital Summit",
    desc: "Whole Body Digital Twin Technology for Reversal of Chronic Diseases with Simultaneous Benefits to Reduce the Burden of Cardiovascular Disease (Abstract)",
    url: "",
  },
  {
    id: 124,
    title: "Diabetes - American Diabetes Association Journal",
    desc: "Effect Of Digital Twin Technology For Remission Of Diabetes On The Glycemic And Extra Glycemic Parameters : Interim Results Of The Prospective Randomised Controlled Clinical Trial (Abstract).",
    url: "https://diabetesjournals.org/diabetes/article/70/Supplement_1/767-P/140492/767-P-Effect-of-Digital-Twin-Technology-for",
  },
  {
    id: 125,
    title: "Endocrine Practice - the Journal for Clinical Endocrinology",
    desc: "Health Economic Analysis of Digital Twin Technology for Remission of Diabetes - Initial Insights from the Prospective Randomised Controlled Clinical Trial (Abstract).",
    url: "https://www.endocrinepractice.org/article/S1530-891X(21)01203-9/fulltext",
  },
  {
    id: 126,
    title: "Endocrine Practice - the Journal for Clinical Endocrinology",
    desc: "Initial Insights For The Changes In The Cardiometabolic Parameters Using The Twin Technology Enabled Precision Nutrition (Abstract).",
    url: "https://www.endocrinepractice.org/article/S1530-891X(21)01202-7/fulltext",
  },
  {
    id: 127,
    title: "Endocrine Practice - the Journal for Clinical Endocrinology",
    desc: "Digital Twin Enabled Precision Nutrition Induces Remission of Diabetes Independent of Improvement in the Markers of Obesity (Abstract).",
    url: "https://www.endocrinepractice.org/article/S1530-891X(21)00771-0/fulltext",
  },
  {
    id: 128,
    title:
      "Proceedings of the European Diabetic Nephropathy Study Group (EDNSG)",
    desc: "Relationship between Renal Function and Glycemic Changes in Type 2 Diabetes Mellitus: Initial Insights from the Randomised Trial Evaluating Whole-Body Digital Twin Technology .",
    url: "",
  },
  {
    id: 129,
    title: "Obesity Facts",
    desc: "Relationship between Obesity, Insulin Resistance and Glycemic Changes in Type 2 Diabetes Mellitus: Initial Insights from the Randomised Trial Evaluating Whole-Body Digital Twin Technology Enabled Precision Nutrition for Reversal of Diabetes (Abstract)",
    url: "https://karger.com/ofa/article/14/Suppl.%201/1/239574/European-Congress-on-Obesity-ECO-Online-2021-28th",
  },
  {
    id: 130,
    title: "Journal of the Endocrine Society",
    desc: "Response to Twin Enabled Precision Treatment for Reversing Diabetes: An Initial Analysis at 4 weeks (Abstract)",
    url: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC8090052/",
  },
  {
    id: 131,
    title: "European Heart Journal, Acute Cardiovascular Care",
    desc: "Combined Improvement of Insulin Resistance and Hypertension Using Whole Body Digital Twin Technology in Diabetic Hypertensives- A Real World Study (Abstract)",
    url: "https://academic.oup.com/ehjacc/article/10/Supplement_1/zuab020.234/6252087",
  },
  {
    id: 132,
    title: "Argentine Journal of Endocrinology and Metabolism",
    desc: "Correlation between the Glycated Hemoglobin and HOMA indices in Type 2 Diabetes Mellitus: Impact of Whole-Body Digital Twin Technology enabled Precision Nutrition (Abstract).",
    url: "",
  },
  {
    id: 133,
    title: "Diabetes Technology & Therapeutics",
    desc: "Digital Twin Technology Intervention To Achieve And Maintain The Glycemic Variability Within The Targets In T2DM– Insights From Real World Study (Abstract).",
    url: "https://www.liebertpub.com/doi/10.1089/dia.2021.2525.abstracts",
  },
  {
    id: 134,
    title: "Metabolism: Clinical and Experimental",
    desc: "Metabolic Benefits Beyond Glycemic Control with Improvement in Insulin Resistance and Testosterone: Impact of Twin Enabled Precision Nutrition (Abstract).",
    url: "https://www.metabolismjournal.com/article/S0026-0495(20)30555-2/fulltext",
  },
  {
    id: 135,
    title: "Endocrine Abstracts",
    desc: "Utilising Internet of Things and Artificial Intelligence to Enable Twin Precision Treatment for Reversal of Type 2 Diabetes (Abstract)",
    url: "https://www.endocrine-abstracts.org/ea/0070/ea0070aep1027",
  },
  {
    id: 136,
    title: "Clinical Diabetes and Endocrinology",
    desc: "Type 2 diabetes reversal with digital twin technology-enabled precision nutrition and staging of reversal: a retrospective cohort study 7, 21 (2021).",
    url: "https://clindiabetesendo.biomedcentral.com/articles/10.1186/s40842-021-00134-7",
  },
  {
    id: 137,
    title: "Scientific Reports - Nature",
    desc: "Retrospective Study of Glycemic Variability, BMI, and Blood Pressure in Diabetes Patients in the Digital Twin Precision Treatment Program, 11, 14892 (2021)",
    url: "https://www.nature.com/articles/s41598-021-94339-6",
  },
  {
    id: 138,
    title: "Diabetes Therapy, September 2020",
    desc: "Reducing HbA1c in Type 2 Diabetes Using Digital Twin Technology-enabled Twin Precision Treatment: A Retrospective Analysis. Diabetes Therapy, 2020 Nov;11(11):2703-14.",
    url: "https://pubmed.ncbi.nlm.nih.gov/32975712/",
  },
  {
    id: 139,
    title: "European Heart Journal",
    desc: "Remission of T2DM by Digital Twin Technology with Reduction of Cardiovascular Risk: Interim Results of Randomised Controlled Clinical Trial (Abstract).",
    url: "https://academic.oup.com/eurheartj/article/43/Supplement_1/ehab849.177/6521199",
  },
];
