// extracted by mini-css-extract-plugin
export var bookpackForm__lngFormInput = "GetInTouch-module--bookpackForm__lngFormInput--MTSHF";
export var checkLabel = "GetInTouch-module--checkLabel--rSHsP";
export var checkbox = "GetInTouch-module--checkbox--dei-+";
export var closeBtn = "GetInTouch-module--closeBtn--ROuke";
export var formInput = "GetInTouch-module--formInput--ENJLV";
export var getInTouch_container = "GetInTouch-module--getInTouch_container--UEXhu";
export var getInTouch_heading = "GetInTouch-module--getInTouch_heading--P4K3C";
export var getInTouch_section = "GetInTouch-module--getInTouch_section--nfcpk";
export var invalid = "GetInTouch-module--invalid--IE1CE";
export var submitBtn = "GetInTouch-module--submitBtn--jg-gc";
export var thankYouMsg = "GetInTouch-module--thankYouMsg--V2u8f";
export var thankYouMsg_image = "GetInTouch-module--thankYouMsg_image--dlZVG";
export var thankYouMsg_line = "GetInTouch-module--thankYouMsg_line--YKPEZ";