import React from "react";

// styles
import * as styles from "./GetInTouch.module.css";

// components
import GetInTouchForm from "./GetInTouchForm";

const GetInTouch = props => {
  return (
    <div className={styles.getInTouch_section}>
      <div className={`container ${styles.getInTouch_container}`}>
        <div className="row">
          <div className="col-12 text-center">
            <h2 className={styles.getInTouch_heading}>Get in touch</h2>
          </div>
        </div>

        <div className="row">
          <div className={`col-12`}>
            <GetInTouchForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default GetInTouch;
