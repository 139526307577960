import React from "react";

// css
import * as styles from "./ReviewJournalV1.module.css";

// dataset
import { payload } from "./dataSet";

const ReviewJournal = props => {
  return (
    <div className={styles.journal_section}>
      <div className={styles.journal_heading_card}>
        <h2 className={styles.journal_heading}>Publications</h2>
      </div>

      <div className="container">
        <div className="row mt-3">
          {payload.map((item, index) => (
            <div key={item.id} className={`col-12 col-md-4 mt-4 text-center`}>
              <div className={`${styles.journal_card}`}>
                <h3 className={styles.journal_card_title}>{item.title}</h3>
                <p className={styles.journal_card_desc}>{item.desc}</p>
                {item.url ? (
                  <a
                    href={item.url}
                    className={`btn ${styles.journal_button}`}
                    target={
                      props.appHeader === "mobile" || props.twinApp === "yes"
                        ? "_parent"
                        : "_blank"
                    }
                    rel="noreferrer noopener"
                  >
                    View
                  </a>
                ) : (
                  <div style={{ height: "52px" }}></div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReviewJournal;
