import React from "react";
import { navigate } from "gatsby";
import styled from "styled-components";
// import { Image, Placeholder } from "cloudinary-react";
import { StaticImage } from "gatsby-plugin-image";

// config
// import config from "../../config";

const MainWrapper = styled.div`
  background: #ffffff;
  width: 100% !important;
  height: 70px;
  line-height: 70px;
  position: fixed;
  top: 0px;
  z-index: 1000;
  max-width: 768px !important;
`;

const HeaderText = styled.div`
  img {
    height: 40px;
    text-align: center;
    margin-top: 12px;
  }
`;

const ImageWrapper = styled.div`
  span {
    margin-left: 10px;
    cursor: pointer;
    color: #111111;
  }
`;

const HeaderSpace = styled.div`
  height: 70px;
`;

const AppHeader = props => {
  const handleBack = () => {
    if (props.backUrl && props.backUrl !== "mobile_back") {
      navigate(props.backUrl);
    } else if (props.backUrl && props.backUrl === "mobile_back") {
      // handle events for mobile app
      if (window.twinApp) {
        let data = JSON.stringify({ action: "app_back" });
        window.twinApp.postMessage(data);
      } else if (window.webkit) {
        window.webkit.messageHandlers.twinApp.postMessage({
          action: "app_back",
        });
      } else {
        console.log("Back from twin app page");
      }
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <MainWrapper className={`container`}>
        <div className="d-flex justify-content-between align-items-center">
          <ImageWrapper>
            {props.isBack ? (
              <span
                onClick={() => handleBack()}
                role="presentation"
                className={`icon-back smallIcon cursorPointer`}
                style={{ fontSize: "18px" }}
              >
                {/* <Image
                  secure="true"
                  cloudName={config.CLOUDINARY_NAME}
                  publicId="app-images/back_es35bo"
                  dpr="auto"
                  responsive
                  width="19"
                  crop="scale"
                  responsiveUseBreakpoints="true"
                  fetchFormat="webp"
                  quality="auto"
                >
                  <Placeholder type="blur" />
                </Image> */}
              </span>
            ) : (
              <div style={{ width: "30px" }}></div>
            )}
          </ImageWrapper>
          <HeaderText>
            <StaticImage
              src="../../images/twin-logo/twinLogo2_2024.png"
              alt="Banner"
              placeholder="blurred"
              quality={90}
            />
          </HeaderText>
          <div style={{ width: "30px" }}></div>
        </div>
      </MainWrapper>

      <HeaderSpace></HeaderSpace>
    </>
  );
};

export default AppHeader;
